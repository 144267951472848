.content-holder {

    // margin-top: 30px;
    // margin-left: 200px;
    .banner-image {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            object-fit: cover;
            // position: absolute;
            height: 355px;
            width: 100%;
        }

        h5 {
            position: absolute;
            font-family: Outfit;
            font-size: 96px;
            font-weight: 700;
            line-height: 92px;
            text-align: left;
            color: #ffffff;
        }
    }

    .content {
        padding: 50px 70px;
        width: 90%;

        .accordion-item {
            margin-bottom: 30px;
            border: 1.5px solid #c5c5c7;
            border-radius: 16px;

            .accordion-header {
                padding: 0px 20px;

                .accordion-button {
                    font-family: Outfit;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 32px;
                    letter-spacing: 0px;
                    height: 95px;
                    text-align: left;
                    color: #3a393b;
                    position: relative;

                    &:focus {
                        border-color: unset;
                        box-shadow: none;
                    }
                }
            }

            .accordion-button:not(.collapsed) {
                background-color: transparent;
                box-shadow: none;

                &::after {
                    background-image: url("../../../public/png-icons/minus.png");
                    top: 35px;
                    width: 14px;
                    height: 14px;
                    position: absolute;
                    right: 25px;
                    background-size: contain;
                }
            }

            .accordion-button::after {
                flex-shrink: 0;
                top: 40px;
                position: absolute;
                right: 25px;
                width: 14px;
                height: 14px;
                content: "";
                background-image: url("../../../public/png-icons/plus.png");
                background-repeat: no-repeat;
                background-size: contain;
                // transition: transform 0.2s ease-in-out;
            }

            .accordion-body {
                margin: 0px 25px;
                margin-top: -25px;
                font-family: Outfit;
                font-size: 20px;
                font-weight: 400;
                line-height: 26px;
                letter-spacing: 0em;
                text-align: left;
                color: #667085;
            }
        }
    }
}

@media (max-width: 767px) {
    .content-holder {
        padding-left: 0px;
        padding-right: 0px;

        .banner-image {
            img {
                height: 200px !important;
            }

            h5 {
                font-size: 55px !important;
            }
        }

        .content {
            width: 100%;
            padding: 50px 30px;

            .accordion-item {
                .accordion-header {
                    padding: 0px 5px;
                }

                .accordion-body {
                    margin: 0px 10px;
                }
            }
        }
    }
}