$dark-background: #f66817;

.favorite-recipes {
    margin-top: 20px;

    h2 {
        color: #212529;
        font-size: 32px;
        font-weight: 500;
        line-height: 30px;
        font-family: Outfit;
    }
    .fav-header {
        margin-top: 20px;
        box-shadow: 0px 5.03px 11.74px 0px #00000014;
        border-radius: 30px;
        background: #ffffffa3;
        padding: 10px;
        border: 0.34px solid #e1e1e3;
        .fav-input {
            display: flex;
            align-items: center;
            .btn-secondary {
                color: #fff;
                background-color: #717477 !important;
                border-color: none !important;
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
            input {
                border: none;
                width: 90%;

                &:focus {
                    box-shadow: none;
                    outline: none;
                }
            }
            button {
                border-radius: 20px;
                font-family: Outfit;
                font-size: 13.42px;
                font-weight: 500;
                line-height: 20.12px;
                text-align: center;
                width: 110px;
                height: 41px;
                color: #fafafa;
                background: #f66817;
                border: none;
            }
        }
    }
    .content-holder {
        padding-left: 0px;
        padding-right: 0px;

        .content {
            padding: 15px 0px;

            #accord {
                .accordion-item {
                    border: none;
                    width: 72vw;
                    .accordion-button::after {
                        display: none;
                    }

                    h2 {
                        font-family: Outfit;
                        font-size: 30px;
                        font-weight: 600;
                        line-height: 35.48px;
                        text-align: left;
                        color: #212529;
                        letter-spacing: 1px;
                        min-height: 85px;
                    }

                    h3 {
                        font-family: Outfit;
                        font-size: 24px;
                        font-weight: 600;
                        line-height: 30px;
                        text-align: left;
                        color: #525252;
                        letter-spacing: 1px;
                    }

                    p {
                        font-family: Outfit;
                        font-size: 24px;
                        font-weight: 400;
                        line-height: 40px;
                        text-align: left;
                        color: #525252;
                        padding-left: 20px;
                    }

                    .recipe-img-box {
                        float: right;
                    }

                    .recipe-img {
                        border-radius: 24px;
                        width: 200px;
                        height: 100px;
                    }

                    .recipe-right {
                        padding-left: 3rem;
                    }

                    .accordion-header {
                        padding: 0px 0px;
                        border-radius: 24px;
                        border: 2px solid #ff833b;
                        height: 120px;
                        overflow: hidden;

                        .accordion-button {
                            padding: 8px 15px !important;
                            .w-70 {
                                width: 70% !important;
                            }
                            .w-30 {
                                width: 30% !important;
                            }
                            .w-50 {
                                width: 50% !important;
                            }
                        }
                    }

                    .active-acc {
                        border-radius: 24px 24px 0px 0px;
                        border-bottom: none !important;
                        background-color: #ffefe5;
                        height: unset !important;
                        overflow: unset !important;
                    }

                    .active-acc-body {
                        background-color: #ffefe5;
                        border-radius: 0px 0px 24px 24px !important;
                        border-top: none !important;
                    }

                    .accordion-body {
                        // margin-top: 0px;
                        margin: 0px 0px;
                        // border: 2px solid #ff833b;
                        // border-radius: 24px
                        border: 2px solid #ff833b;

                        .padd {
                            padding-left: 1rem !important;
                        }

                        .view-img {
                            padding-left: 18px;
                            padding-top: 10px;

                            button {
                                background-color: #f66817;
                                color: #fff;
                                border-radius: 4px;
                                border: none;
                                width: 180px;
                                height: 48px;
                                font-family: Outfit;
                            }
                        }

                        .plating-img {
                            width: 150px;
                            height: 150px;
                            border-radius: 24px;
                            cursor: pointer;
                        }
                    }
                }

                .accordion-item .accordion-header .accordion-button {
                    height: unset;
                    padding: 0px 10px;
                }

                // .accordion-item:hover,
                .accordion-button:hover,
                // .accordion-item:hover~.accordion-button,
                .accordion-button:hover~.accordion-item {
                    background-color: #ffefe5;
                    border-radius: 24px;
                }
            }
            .mt-15 {
                margin-top: 7px;
            }
            .recipe-item {
                margin-bottom: 30px;
                padding: 20px;
                border: 1px solid #999;
                border-radius: 10px;

                h1 {
                    font-size: 34px;
                    font-weight: bold;
                    text-align: center;
                    margin-bottom: 30px;
                }

                h2 {
                    font-size: 30px;
                    text-align: left;
                    margin-bottom: 10px;
                    background-color: #f1d689;
                    border-radius: 5px;
                    padding: 5px 10px 5px 10px;
                    font-weight: 500;
                }

                h3 {
                    font-size: 26px;
                    text-align: left;
                    margin-bottom: 10px;
                    padding: 5px 10px 5px 10px;
                    font-weight: 500;
                }

                p {
                    padding-left: 30px;
                    font-size: 20px;
                    margin-bottom: 10px;
                    padding: 0px 10px 5px 10px;
                    white-space: pre-wrap;
                }

                ol,
                ul {
                    padding-left: 30px;
                }

                li {
                    font-size: 20px;
                }
            }
        }

        .no-recipes-found {
            text-align: center;
            font-size: 30px;
            padding: 50px;
        }
    }
}

@media (max-width: 767px) {
    .favorite-recipes {
        .fav-header {
            padding: 15px;
            width: 95%;
            .fav-input {
                flex-wrap: wrap;
                input {
                    border: 1px solid $dark-background;
                    &:focus {
                        border: 1px solid $dark-background;
                    }
                }
            }
            button {
                margin-top: 15px;
            }
        }
        .content-holder {
            #accord {
                .accordion-item {
                    h2 {
                        font-size: 20px !important;
                        line-height: 28px;
                    }

                    h3 {
                        font-size: 20px !important;
                        margin-bottom: 0px;
                    }

                    p {
                        font-size: 20px !important;
                    }

                    .exec-name {
                        font-size: 14px !important;
                    }

                    .recipe-img-box {
                        float: none !important;
                    }

                    .recipe-img {
                        width: 290px !important;
                        height: 150px !important;
                    }

                    .recipe-right {
                        padding-left: 0.7rem !important;
                    }

                    .accordion-header {
                        height: 280px !important;

                        .accordion-button {
                            padding: 20px 20px !important;
                        }
                    }
                }
            }
        }
        .content-holder .content #accord .accordion-item {
            width: 92vw;
        }
    }
}
