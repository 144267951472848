.my-subscription-holder {
    width: 72vw;
    margin-top: 30px;

    h2 {
        color: #212529;
        font-size: 32px;
        font-weight: 500;
        line-height: 30px;
        font-family: Outfit;

        .clear-btn {
            display: flex;
            align-items: center;
            background-color: #ff0000;
            border: none;
            height: 41px;
            padding: 5px 20px;
            border-radius: 24px;
            font-family: Outfit;
            font-size: 15px;
            font-weight: 500;
            line-height: 20.12px;
            text-align: left;
            color: #fff;
        }
    }

    .expire {
        border: 1.5px solid #505050 !important;
        background: #3a3a3a29 !important;
        opacity: 0.4;
    }

    .subscription-item {
        margin: 40px 0px;
        // width: 300px;
        // min-height: 270px;
        border: 1px solid #ff833b;
        padding: 20px;
        padding-bottom: 0px;
        border-radius: 16px;
        background: #ad1a0029;

        p {
            font-family: Outfit;
            font-size: 17px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: -0.005em;
            text-align: left;
            color: #b11b00;
        }

        .sub-price {
            color: #b11b00;
        }

        .master-catlog {
            margin-top: 5px;
            font-family: Outfit;
            font-size: 32px;
            font-weight: 700;
            line-height: 52px;
            // letter-spacing: -0.02em;
            text-align: left;
            color: #b11b00;
            text-transform: capitalize;
        }

        .sub-date {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-family: Outfit;
            font-size: 18px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: -0.005em;
            text-align: left;
            color: #281d1b;
        }

        .hr {
            border: 1.5px solid #6e4f4933;
            margin-top: 0px;
        }

        .sub-desc {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;

            ul {
                padding-left: 0px;
                list-style: none;
                margin-bottom: 0px;

                li {
                    font-family: Outfit;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 16px;
                    // letter-spacing: -0.005em;
                    text-align: left;
                    color: #2e18149e;
                }
            }

            .order-details {
                // margin-top: 10px;
                margin-bottom: 14px;

                p {
                    font-family: Outfit;
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 20px;
                    text-align: left;
                    color: #231f20;
                }

                h5 {
                    margin-left: 80px;
                    font-family: Outfit;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 20px;
                    text-align: right;
                    color: #231f20;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .my-subscription-holder {
        margin-top: 20px;
        width: 92vw;
        .subscription-item {
            padding: 15px;
            .master-catlog {
                margin-top: 0px;
            }
            .hr {
                margin-top: 17px;
            }
            .sub-date {
                justify-content: flex-start;
                margin-top: 10px;
            }
            .sub-desc {
                flex-wrap: wrap;
                .order-details {
                    margin-top: 20px;
                }
            }
        }
    }
}
