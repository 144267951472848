$button-color: #f66817;

.App {
  text-align: center;
  color: #000000;
}

.cursor-pointer {
  cursor: pointer;
}

.form-group {
  margin-bottom: 1rem;
}

.error-message {
  color: red;
  margin: 5px 0px;
}

.bg-orange {
  background-color: #f66817 !important;
}

.button-color {
  color: #fff;
  background-color: #f66817;
  border: 1px solid #f66817;
  border-radius: 4px;
  // text-transform: uppercase;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease-out;

  &:hover {
    background-color: transparent;
    color: #f66817;
  }

  // &:focus {
  //   background-color: transparent;
  //   border: 1px solid #f66817;
  //   box-shadow: none;
  //   color: #f66817;
  // }
}

p {
  margin-bottom: 0px;
}

.lightbox-wrapper {
  .sticky-close {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 30px;
    top: 25px;
    cursor: pointer;
    z-index: 100000;

    img {
      width: 15px;
    }
  }

  p {
    margin: 1.5em 0;
    color: #aaa;
  }

  img {
    max-height: 85vh;
  }

  a {
    color: inherit;
  }

  a:hover {
    color: #bbb;
  }

  .small {
    font-size: 0.8em;
  }

  /** LIGHTBOX MARKUP **/

  .lightbox {
    /* Default to hidden */
    display: none;

    /* Overlay entire screen */
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // width: 100vw;
    /* A bit of padding around image */
    padding: 1em;

    /* Translucent background */
    background: rgba(0, 0, 0, 0.8);
  }

  /* Unhide the lightbox when it's the target */
  .lightbox:target {
    display: block;
  }

  .lightbox span {
    /* Full width and height */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    height: 100%;

    img {
      // width: 100%;
      height: 85vh;
    }
  }
}

.add-button {
  color: #fff;
  background-color: #f66817;
  border: 1px solid #f66817;
  border-radius: 4px;
  // text-transform: uppercase;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease-out;
  width: 140px;

  &:hover {
    background-color: transparent;
    color: #f66817;
  }

  &:focus {
    background-color: transparent;
    border: 1px solid #f66817;
    box-shadow: none;
    color: #f66817;
  }
}

.delete-button {
  color: #fff;
  background-color: #dc3545;
  border: 1px solid #dc3545;
  border-radius: 4px;
  // text-transform: uppercase;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease-out;
  width: 140px;

  &:hover {
    background-color: transparent;
    color: #dc3545;
  }

  &:focus {
    background-color: transparent;
    border: 1px solid #dc3545;
    box-shadow: none;
    color: #dc3545;
  }
}

.h-40 {
  height: 40px;
}

.w-15 {
  width: 15%;
}

.mr-3 {
  margin-right: 30px !important;
}

.img-box {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

.text-left {
  text-align: left !important;
}

// #F78B02
// #FABF01

.no-found-page {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.confirm-modal {
  width: 550px;
  padding: 20px;
  font-family: Outfit;
  .modal-header {
    position: relative;
    border-bottom: none;
    padding: 22px 15px;
    padding-bottom: 0px;
    .sticky-close {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      right: 10px;
      top: 15px;
      cursor: pointer;
      img {
        width: 15px;
      }
    }
    h2 {
      font-size: 24px;
    }
  }
  label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }
  span {
    color: red;
  }
  h1 {
    // text-align: left;
    font-family: "Outfit";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    a {
      text-decoration: underline !important;
    }
  }

  .save-btn {
    margin-top: 24px;
    font-family: Outfit;
    // text-align: right;
    button {
      border: none;
      background-color: $button-color;
      width: 78px;

      height: 40px;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: #fff;
      border-radius: 4px;
      &:nth-child(2) {
        background-color: #fff;
        border: none;
        border: 1px solid #9d99bf;
        color: #222222;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
      }
    }
  }
}

.paginated-form {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  input {
    width: 40%;
  }

  .pagination {
    margin-bottom: 0px;
  }
}

@media (max-width: 767px) {
  .confirm-modal {
    width: 350px;
  }
}
