.subscription-holder {
    background-color: #fffbfa;
    min-height: 700px;
    padding: 20px 50px;
    padding-top: 70px;

    h3 {
        font-family: Outfit;
        font-size: 64px;
        font-weight: 700;
        line-height: 68px;
        // letter-spacing: -0.02em;
        text-align: center;
        color: #281d1b;
    }

    p {
        font-family: Outfit;
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
        // letter-spacing: -0.005em;
        text-align: center;
    }

    .subscription-items {
        margin-top: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 30px;

        .subscription-item {
            width: 397px;
            min-height: 316px;
            border: 1px solid transparent;
            padding: 20px;
            border-radius: 16px;
            background: #7e322517;

            &:hover {
                p {
                    color: #b11b00;
                }

                .sub-price {
                    color: #b11b00;
                }

                background: #ad1a0029;
                border: 1px solid #ff833b;

                .buy-btn {
                    button {
                        background-color: #ff833b;
                        border: 1.5px solid #ff833b;
                    }
                }
            }

            .master-catlog {
                margin-top: 10px;
                font-family: Outfit;
                font-size: 13px;
                font-weight: 700;
                line-height: 16px;
                letter-spacing: -0.005em;
                text-align: left;
                color: #2e18149e;
            }

            p {
                font-family: Outfit;
                font-size: 17px;
                font-weight: 700;
                line-height: 24px;
                text-align: left;
            }

            .sub-price {
                margin-top: 10px;
                font-family: Outfit;
                font-size: 48px;
                font-weight: 700;
                line-height: 52px;
                text-align: left;
                color: #281d1b;

                span {
                    font-family: Outfit;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 24px;
                    // letter-spacing: -0.005em;
                    text-align: left;
                }
            }

            .buy-btn {
                margin-top: 15px;

                button {
                    height: 36px;
                    border-radius: 8px;
                    font-family: Outfit;
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 20px;
                    text-align: center;
                    color: #281d1b;
                    border: 1.5px solid #6e4f4933;
                }
            }

            .hr {
                border: 1.5px solid #6e4f4933;
                margin-top: 20px;
            }

            .sub-desc {
                margin-top: 20px;

                ul {
                    padding-left: 0px;
                    list-style: none;
                    margin-bottom: 0px;

                    li {
                        font-family: Outfit;
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 16px;
                        // letter-spacing: -0.005em;
                        text-align: left;
                        color: #2e18149e;
                    }
                }
            }
        }
    }
}

.checkout-holder {
    padding: 40px;

    .checkout-div {
        box-shadow: 0px 1px 4px 0px #00000040;
        padding: 15px;

        .subscription-item {
            margin: 40px 0px;
            width: 300px;
            min-height: 270px;
            border: 1px solid #ff833b;
            padding: 20px;
            border-radius: 16px;

            p {
                color: #b11b00;
            }

            .sub-price {
                color: #b11b00;
            }

            background: #ad1a0029;
            border: 1px solid #ff833b;

            .master-catlog {
                margin-top: 10px;
                font-family: Outfit;
                font-size: 13px;
                font-weight: 700;
                line-height: 16px;
                letter-spacing: -0.005em;
                text-align: left;
                color: #2e18149e;
            }

            p {
                font-family: Outfit;
                font-size: 17px;
                font-weight: 700;
                line-height: 24px;
                text-align: left;
            }

            .sub-price {
                margin-top: 10px;
                font-family: Outfit;
                font-size: 48px;
                font-weight: 700;
                line-height: 52px;
                text-align: left;
                color: #281d1b;

                span {
                    font-family: Outfit;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 24px;
                    // letter-spacing: -0.005em;
                    text-align: left;
                }
            }

            .hr {
                border: 1.5px solid #6e4f4933;
                margin-top: 20px;
            }

            .sub-desc {
                margin-top: 20px;

                ul {
                    padding-left: 0px;
                    list-style: none;
                    margin-bottom: 0px;

                    li {
                        font-family: Outfit;
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 16px;
                        // letter-spacing: -0.005em;
                        text-align: left;
                        color: #2e18149e;
                    }
                }
            }
        }

        ul {
            list-style: none;
            padding-left: 0px;

            li {
                font-family: Outfit;
                font-size: 18px;
                font-weight: 400;
                line-height: 25.2px;
                text-align: left;
                color: #7f7f7f;
            }
        }
    }

    .order-summary {
        box-shadow: 0px 1px 4px 0px #00000040;
        padding: 15px;

        .user-ip {
            padding-bottom: 10px;
            margin-top: 1rem;

            input {
                // width: 80%;
                border-radius: 0px;
                height: 40px;
            }

            .apply-btn {
                background-color: #f66817;
                color: #fff;
                border: none;
                height: 40px;
                width: 95px;
            }
        }

        h3 {
            padding: 20px 10px;
            font-family: Outfit;
            font-size: 24px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: #373737;
        }

        .order-details {
            margin-top: 10px;

            p {
                font-family: Outfit;
                font-size: 16px;
                font-weight: 300;
                line-height: 20px;
                text-align: left;
                color: #231f20;
            }

            h5 {
                margin-bottom: 14px;
                font-family: Outfit;
                font-size: 16px;
                font-weight: 600;
                line-height: 20px;
                text-align: right;
                color: #231f20;
            }
        }

        .pay-btn {
            background-color: #f66817;
            height: 50px;
            text-align: center;
            font-family: Outfit;
            font-size: 16px;
            font-weight: 500;
            line-height: 24.19px;
            color: #fff;
            border: none;
            border-radius: 0px;
            margin-top: 30px;
        }
    }
}

@media (max-width: 767px) {
    .subscription-holder {
        padding: 20px 10px;
        padding-top: 30px;
        h3 {
            font-family: Outfit;
            font-size: 36px;
            font-weight: 700;
            line-height: 40px;
        }
    }
    .checkout-holder {
        padding: 0px;
    }
}
