$dark-background: #f66817;

.not-show {
    display: none;
}

.homepage-holder {
    margin-left: 0px;
    margin-bottom: 50px;

    .wrapper {
        position: absolute;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        z-index: 0;
        border-radius: 12px;
    }

    .banner-holder {
        .banner-img {
            height: 400px;
        }

        .search-sticky {
            position: absolute;
            width: 80%;
            height: 85px;
            padding: 15px;
            top: 45%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 85px;
            display: flex;
            align-items: center;
            border: 0.4px solid #e1e1e3;
            box-shadow: 0px 6px 14px 0px #00000014;
            background: linear-gradient(0deg, hsla(0, 0%, 100%, 0.64), hsla(0, 0%, 100%, 0.64));

            .search-select {
                border-radius: 28px;
                width: 20%;
                background-color: transparent;
                border: 1px solid #ff6913;
                background-image: url("../../assets/arrow-down.svg");
                background-position: 9% 50%;
                background-size: 20px 20px;
                background-repeat: no-repeat;
                padding-left: 44px;
                font-family: Outfit;
                font-size: 16px;
                font-weight: 900;
                line-height: 24px;
                text-align: left;
                color: #f66817;
                height: 38px;
            }

            .has-search {
                width: 90%;
                position: relative;
                padding-left: 20px;

                .form-control {
                    padding-left: 0rem;
                }

                .form-control-feedback {
                    position: absolute;
                    z-index: 5;
                    display: block;
                    width: 20px;
                    height: 2.375rem;
                    line-height: 2.375rem;
                    text-align: center;
                    pointer-events: none;
                    color: #aaa;
                    margin-top: -3px;
                    margin-left: 5px;
                }

                #search {
                    border: none;
                    background: transparent;

                    &:focus {
                        outline: none;
                        border: none;
                        box-shadow: none;
                    }

                    &::-webkit-input-placeholder {
                        color: #000000;
                        font-size: 16px;
                    }

                    &::-moz-placeholder {
                        color: #000000;
                        font-size: 16px;
                    }

                    &:-ms-input-placeholder {
                        color: #000000;
                        font-size: 16px;
                    }

                    &::placeholder {
                        color: #000000;
                        font-size: 16px;
                    }
                }
            }

            .search-btn {
                border-radius: 85px;
                color: #fafafa;
                background-color: $dark-background;
                border: none;
                height: 48px;
                width: 98px;
                //styleName: Outfit - M/Heading/H4;
                font-family: Outfit;
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0px;
                text-align: center;
            }
        }
    }

    .language-tabs {
        padding: 30px 150px;
        // margin-top: 40px;

        p {
            color: #8d8c8f;
            // font-family: MADE TOMMY;
            font-size: 18px;
            font-weight: 500;
            line-height: 26px;
            letter-spacing: -0.01em;
            text-align: center;
        }

        .slick-track {
            display: flex !important;
            align-items: stretch !important;
        }

        .slick-slide {
            height: auto !important;
            margin-right: 25px;
        }

        .slick-disabled {
            // opacity: 0% !important;
            cursor: default !important;
        }

        .slick-list {
            overflow: hidden;
            margin: 0;
            padding: 0;
        }

        .slick-next {
            cursor: pointer;
            position: absolute;
            right: -20px;
            top: 8px;

            &:before {
                font-size: 0px;
                opacity: 0;
                display: none;
            }
        }

        .slick-prev {
            cursor: pointer;
            position: absolute;
            left: -50px;
            top: 31px;

            &:before {
                opacity: 0;
                display: none;
                font-size: 0px;
            }
        }

        .lang-btn {
            background-color: #fff;
            border: 1px solid #c5c5c7;
            // width: 150px;
            height: 46px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            cursor: pointer;
            font-weight: 400;
            font-size: 18px;
            color: #555557;
        }

        .active {
            background-color: #ff6913;
            color: #fafafa;
        }
    }

    .build-recipe {
        --gap: 22px;
        --gap-sm: 0.5rem;
        --radius: 12px;
        // margin-top: 40px;
        padding: 30px 200px;

        h3 {
            font-size: 32px;
            font-weight: 600;
            line-height: 40px;
            letter-spacing: 0em;
            text-align: center;
            color: #1b1a1f;
        }

        .bg-yellow {
            // background: #f3eeeb;
            padding: 10px 30px;
            border-radius: 32px;
        }

        .goal-preference {
            .error-msg {
                color: red;
                margin-left: 40px;
            }

            .label {
                font-family: Outfit;
                font-size: 32px;
                font-weight: 600;
                line-height: 40px;
                text-align: left;
                color: #525252;
            }

            .pi-input {
                width: 150px;
                margin-left: 40px;
                height: 70px;
                border: 2px solid #c5c5c7;
                border-radius: 20px;
                font-family: Outfit;
                font-size: 22px;
                font-weight: 500;
                line-height: 32px;
                // text-align: center;
                color: #101828;
            }

            textarea {
                width: 765px;
                margin-left: 40px;
                // height: 70px;
                border: 2px solid #c5c5c7;
                border-radius: 20px;
                font-family: Outfit;
                font-size: 22px;
                font-weight: 500;
                line-height: 32px;
                // text-align: center;
                color: #101828;
            }

            .dynamic-input {
                input {
                    width: 765px;
                    margin-left: 112px;
                    height: 70px;
                    border: 2px solid #c5c5c7;
                    border-radius: 20px;
                    font-family: Outfit;
                    font-size: 22px;
                    font-weight: 500;
                    line-height: 32px;
                    // text-align: center;
                    color: #101828;
                }

                .add-btn {
                    background: none;
                    border: none;
                    box-shadow: none;
                    margin-left: 35px;

                    img {
                        width: 3;
                        height: 30px;
                    }
                }

                .remove-btn {
                    background: none;
                    border: none;
                    box-shadow: none;
                    height: 0px;
                    position: absolute;
                    top: 20px;
                    right: 35px;

                    img {
                        width: 30px;
                        height: 30px;
                    }
                }
            }

            .custom-radio .form-check-input {
                border-radius: 50%;
                width: 1.2em;
                height: 1.2em;
                margin-top: 0px;
                cursor: pointer;

                &:checked[type="radio"] {
                    background-image: url("../../assets/dot.svg");
                }

                &:checked {
                    background-color: #fff;
                    /* Change this to your preferred dot color */
                    border-color: $dark-background;
                }

                &:focus {
                    box-shadow: none;
                }

                &:checked::before {
                    background-color: $dark-background;
                    /* Change this to the color of the inner dot (default white) */
                }
            }

            .meal-type {
                margin-top: 30px;

                .checkbox-group {
                    margin-top: 20px;

                    // margin-left: 20px;
                    .form-check {
                        padding-left: 3rem;
                    }

                    input[type="checkbox"] {
                        border-radius: 3px;
                        width: 20px;
                        height: 20px;
                        margin-right: 0px;
                        margin-top: 0px;
                        font-family: Outfit;
                        font-size: 26px;
                        font-weight: 500;
                        line-height: 39.17px;
                        text-align: left;

                        &:checked[type="checkbox"] {
                            // background-image: url("../../assets/dot.svg");
                        }

                        &:checked {
                            background-color: $dark-background;
                            /* Change this to your preferred dot color */
                            border-color: $dark-background;
                        }

                        &:focus {
                            box-shadow: none;
                        }

                        &:checked::before {
                            background-color: $dark-background;
                            /* Change this to the color of the inner dot (default white) */
                        }
                    }
                }
            }
        }

        .recipe-cards {
            margin-top: 40px;

            width: 100%;

            .card {
                border-radius: var(--radius);
                width: 100%;
                max-width: 100%;
                padding: 12px;
                background: #ffffff;
                border: 2px solid #c5c5c7;
                box-sizing: border-box;

                //    box-shadow: -5px 10px 25px rgba(0, 0, 0, 0.2);
                // }
                .card__img {
                    position: relative;
                    aspect-ratio: 10 / 9;
                    // width: 168px;
                    height: 108px;

                    img {
                        border-radius: var(--radius);
                        object-fit: cover;
                        position: absolute;
                        inset: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                    }
                }

                .card__body {
                    padding: 15px 0;
                    padding-bottom: 2px;

                    h1 {
                        font-family: Outfit;
                        font-size: 26px;
                        font-weight: 500;
                        line-height: 39px;
                        letter-spacing: 0px;
                        text-align: center;
                        color: #101828;
                    }

                    .elipsis {
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }
            }
        }

        .subcatalog-cards {
            margin-top: 40px;
            width: 100%;

            .card {
                border-radius: 14px;
                width: 100%;
                max-width: 100%;
                padding: 14px;
                background: #ffffff;
                border: 2px solid #c5c5c7;
                box-sizing: border-box;

                .card__img {
                    position: relative;
                    aspect-ratio: 10 / 9;
                    // width: 168px;
                    height: 90px;

                    img {
                        border-radius: var(--radius);
                        object-fit: cover;
                        position: absolute;
                        inset: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                    }
                }

                .card__body {
                    h1 {
                        font-family: Outfit;
                        font-size: 26px;
                        font-weight: 500;
                        line-height: 30px;
                        letter-spacing: 0px;
                        text-align: center;
                        color: #1d1d1d;
                    }

                    .elipsis {
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }
            }
        }

        .add-box {
            background-color: #ffffff;
            border: 0.4px solid #e1e1e3;
            border-radius: 46px;
            // height: 88px;
            width: 484px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 25px;
            box-shadow: 0px 6px 14px 0px #00000014;

            input {
                border: none;
            }

            button {
                width: 78px;
                height: 48px;
                border-radius: 32px;
                background-color: #ff6913;
                border: none;
                font-family: Outfit;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0px;
                text-align: center;
                color: #fafafa;
            }
        }

        .search-product {
            margin-top: 30px;
            width: 100%;
            padding: 10px;
            border-radius: 85px;
            display: flex;
            align-items: center;
            background: linear-gradient(0deg, #ffffff, #ffffff);
            border: 0.4px solid #e1e1e3;
            box-shadow: 0px 6px 14px 0px #00000014;

            .has-search {
                width: 90%;
                position: relative;
                padding-left: 40px;

                .form-control {
                    border: none;
                    padding-left: 2.4rem;
                }

                .form-control-feedback {
                    position: absolute;
                    z-index: 5;
                    display: block;
                    width: 20px;
                    height: 2.375rem;
                    line-height: 2.375rem;
                    text-align: center;
                    pointer-events: none;
                    color: #aaa;
                    margin-top: -3px;
                    margin-left: 5px;
                }
            }

            .search-btn {
                border-radius: 85px;
                color: #fafafa;
                background-color: $dark-background;
                border: none;
                height: 48px;
                width: 98px;
                //styleName: Outfit - M/Heading/H4;
                font-family: Outfit;
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0px;
                text-align: center;
            }
        }

        .something {
            margin-top: 30px;
            margin-bottom: 20px;

            .form-control {
                padding-top: 1.2rem;
                padding-left: 2.5rem;
                font-size: 24px;
            }

            textarea {
                border-radius: 46px;
                border: 1px solid #c5c5c7;
                box-shadow: 0px 6px 14px 0px #00000014;
                background: linear-gradient(0deg, #ffffff, #ffffff);
            }
        }

        .my-recipe {
            border: 1px solid #ffc29e;
            border-radius: 46px;
            box-shadow: 0px 6px 14px 0px #00000014;
            min-height: 500px;
            padding: 20px 45px;
            margin-top: 30px;

            label {
                font-family: Outfit;
                font-size: 20px;
                font-weight: 400;
                line-height: 40px;
                letter-spacing: 0em;
                text-align: left;
                color: #abaaad;
            }

            h4 {
                font-family: Outfit;
                font-size: 24px;
                font-weight: 400;
                line-height: 40px;
                letter-spacing: 0em;
                text-align: left;
                color: #212121;
                margin-bottom: 0px;
            }
        }

        .overflow-build-catalog {
            overflow-y: auto;
            max-height: 530px;
            scrollbar-width: thin;
            overflow-x: hidden;

            & ::-webkit-scrollbar-track {
                border-radius: 10px;
                // background-color: #cfcfcf;
            }

            &::-webkit-scrollbar {
                width: 6px;
                background-color: #cfcfcf;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 40px;
                max-height: 5px;
                background-color: #ff6913;
            }
        }

        .overflow-sub-catalog {
            overflow-y: auto;
            max-height: 225px;
            scrollbar-width: thin;
            overflow-x: hidden;

            & ::-webkit-scrollbar-track {
                border-radius: 10px;
                // background-color: #cfcfcf;
            }

            &::-webkit-scrollbar {
                width: 6px;
                background-color: #cfcfcf;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 40px;
                max-height: 5px;
                background-color: #ff6913;
            }
        }

        .overflow-cuisine {
            overflow-y: auto;
            max-height: 310px;
            scrollbar-width: thin;
            overflow-x: hidden;

            & ::-webkit-scrollbar-track {
                border-radius: 10px;
                // background-color: #cfcfcf;
            }

            &::-webkit-scrollbar {
                width: 6px;
                background-color: #cfcfcf;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 40px;
                max-height: 5px;
                background-color: #ff6913;
            }
        }

        .overflow-prod-types {
            overflow-y: auto;
            max-height: 320px;
            scrollbar-width: thin;
            overflow-x: hidden;

            & ::-webkit-scrollbar-track {
                border-radius: 10px;
                // background-color: #cfcfcf;
            }

            &::-webkit-scrollbar {
                width: 6px;
                background-color: #cfcfcf;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 40px;
                max-height: 5px;
                background-color: #ff6913;
            }
        }

        .slick-track {
            display: flex !important;
            align-items: stretch !important;
            margin: auto;
        }

        .slick-slide {
            height: auto !important;
            margin-right: 25px;
        }

        .slick-disabled {
            // opacity: 0% !important;
            cursor: default !important;
        }

        .slick-list {
            overflow: hidden;
            margin: 0;
            padding: 0;
        }

        .slick-next {
            cursor: pointer;
            position: absolute;
            right: -20px;
            top: 48px;

            &:before {
                font-size: 0px;
                opacity: 0;
                display: none;
            }
        }

        .slick-prev {
            cursor: pointer;
            position: absolute;
            left: -50px;
            top: 48px;

            &:before {
                opacity: 0;
                display: none;
                font-size: 0px;
            }
        }
    }

    .my-recipe hr {
        margin: 0.5rem 0;
    }

    .build-btn {
        background-color: $dark-background;
        color: #fff;
        border-radius: 12px;
        border: none;
        height: 60px;
        width: 214px;
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;
    }
}

@media (max-width: 767px) {
    .homepage-holder {
        .banner-holder {
            .banner-img {
                height: 200px !important;
            }

            .search-sticky {
                width: 95%;
                flex-wrap: wrap;
                min-height: 85px;
                height: unset;
                border-radius: 25px;
                padding: 10px 15px;
                box-shadow: 0px 5.03px 11.74px 0px #00000014;

                .search-select {
                    width: 90%;
                    height: 38px;
                    border-radius: 4px;
                    background-position: 5% 50%;
                }

                .has-search {
                    margin-top: 10px;
                    padding-left: 0px;

                    #search {
                        border: 1px solid $dark-background;

                        &:focus {
                            border: 1px solid $dark-background;
                        }
                    }

                    .form-control {
                        padding-left: 1rem;
                    }
                }

                .search-btn {
                    margin-top: 10px;
                    height: 40px;
                }
            }
        }

        .language-tabs {
            padding: 30px 10px;

            ul {
                justify-content: unset;
                overflow: auto;

                li {
                    margin-right: 10px;
                    padding: 20px;
                }
            }
        }

        .padd {
            padding: 30px 25px !important;
        }

        .build-recipe {
            padding: 30px 10px;

            h3 {
                font-size: 28px;
                line-height: 34px;
            }

            .slick-slide {
                margin-right: 5px;
            }

            .slick-prev {
                left: -27px !important;
                // top: 48px;
            }

            .slick-next {
                // top: 48px;
                right: -25px;
            }

            .slick-track {
                opacity: 1;
                transform: translate3d(calc(0px), 0px, 0px);
            }

            .overflow-build-catalog {
                max-height: 580px;
            }

            .overflow-sub-catalog {
                max-height: 205px;
            }

            .overflow-prod-types {
                max-height: 280px;
            }

            .recipe-cards .card .card__body {
                h1 {
                    font-size: 22px;
                    line-height: 30px;
                }

                .elipsis {
                    -webkit-line-clamp: 2;
                }
            }

            .master-catalog {
                .card {
                    padding: 5px;

                    .card__body {
                        height: 70px;
                        padding: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }

            .subcatalog-cards .card .card__body {
                h1 {
                    font-size: 22px;
                    line-height: 30px;
                }

                .elipsis {
                    -webkit-line-clamp: 2;
                }
            }

            .goal-preference {
                .error-msg {
                    margin-left: 10px;
                    margin-bottom: 10px;
                }

                .label {
                    font-size: 24px;
                    line-height: 30px;
                    width: 100%;
                }

                .pi-input {
                    margin-top: 20px;
                    margin-left: 0px;
                    width: 100%;
                }

                textarea {
                    width: 100%;
                    margin-left: 0px;
                    margin-top: 20px;
                }

                .dynamic-input {
                    input {
                        margin-left: 0px;
                        width: 100%;
                    }

                    .form-group {
                        width: 100%;
                    }
                }
            }

            .bg-yellow {
                padding: 10px 10px;
            }
        }
    }
}