$dark-background: #f66817;
// $dark-background: linear-gradient(to left, #f78b02, #fabf01);
$heading: #484750;
$chart-color: #000000;

.profile-overflow {
    & ::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: white;
    }

    &::-webkit-scrollbar {
        width: 6px;
        background-color: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        max-height: 10px;
        background-color: #e9e8e8;
    }

    scrollbar-width: thin;
}

.dashboard-container {
    .navbar {
        height: 90px;
        padding: 0px 90px;

        // padding-right: 90px !important;
        .logo {
            background-color: #fff;
            border-radius: 4px;
            padding: 4px;
        }

        .navbar-nav .dropdown-menu {
            top: 75px;
            left: 33px;
        }

        .navbar-collapse {
            justify-content: center;
            flex-basis: unset;
            flex-grow: 1;

            .navbar-nav {
                .nav-item {
                    cursor: pointer;

                    .nav-link {
                        padding: 35px;
                        color: #fff;
                        font-size: 22px;
                        font-weight: bold;
                    }
                }
            }
        }

        .dashboard-profile-holder {
            // float: right;
            height: 41px !important;
            padding-top: 2px;

            h4 {
                font-size: 20px;
            }

            .drop-inst {
                position: absolute;
                background: white;
                width: 150px;
                right: 112px;
                opacity: 0;
                visibility: hidden;
                transform: translate(10px, 15px);
                transition: all 0.3s ease;
                top: 70px;
                z-index: 9999;
                border-radius: 4px;
                box-shadow: 0 0 10px 0 rgba(82, 63, 105, 0.15);

                @media (max-width: 375px) {
                    width: 300px !important;
                }

                .logout {
                    padding: 14px;
                    color: #212529;
                    font-size: 16px;
                    cursor: pointer;

                    img {
                        width: 20px;
                        height: 20px;
                        vertical-align: middle;
                    }

                    &:hover {
                        background-color: rgba(93, 120, 255, 0.1);
                    }
                }
            }

            .drop-profile-hold {
                width: 40px;
                height: 40px;
                overflow: hidden;
                border: 2px solid #ddd;
                border-radius: 25px;
                cursor: pointer;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;

                img {
                    width: 100%;
                    border-radius: 50%;
                    /* width: 40px; */
                    height: 40px;
                }
            }

            .initial-circle-custom {
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
                font-size: 17px;
                font-weight: 700;
                font-family: Outfit;
                color: #fff;
            }

            .dropmain-holder {
                &:hover {
                    .drop-inst {
                        opacity: 1;
                        visibility: visible;
                        transform: translate(11px, 10px);
                        z-index: 9999;
                        border-radius: 4px;
                    }
                }
            }
        }

        .nav-btns {
            button {
                width: 94px;
                border-radius: 8px;
                border: none;
                background-color: #fff;
                color: #000000;
                font-weight: bold;
                height: 40px;
                padding: 0px 20px;
                width: auto;

                &:nth-child(2) {
                    background-color: #000000;
                    color: #fff;
                    font-weight: bold;
                }
            }
        }
    }

    .side-bar {
        min-width: 225px;
        max-width: 225px;
        // min-width: 220px;
        background-color: $dark-background;
        // position: fixed;
        z-index: 0;
        box-shadow: 0 0 15px 0 rgba(82, 63, 105, 0.08);

        .menu-close {
            height: 10px;
            position: absolute;
            display: none;

            .close {
                // position: relative;
                // top: -67px;
                // left: 273px;

                // @media (max-width: 374px) {
                //     //left: 244px;
                // }
            }

            @media (max-width: 1200px) {
                display: unset;
            }
        }

        .profile-overflow {
            max-height: calc(100vh - 3rem);
            height: 100vh;
            overflow-y: auto;

            .dashboard-links {
                padding-left: 0px;
                margin-right: 0px;

                ul {
                    margin: 0;
                    padding: 0;
                    width: 100%;

                    a {
                        text-decoration: none;
                        color: #000000;
                    }

                    .card {
                        border: none;
                        background-color: transparent;
                        color: #fff;
                        border-radius: 0px;

                        .card-header {
                            padding: 15px 0px;
                            padding-left: 35px;
                            border: none;
                            background-color: transparent;
                            font-family: Outfit;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 14px;
                            letter-spacing: 0px;
                            text-align: left;
                        }
                    }

                    li {
                        list-style-type: none;
                        padding: 15px 0;
                        // background: #fff;
                        padding-left: 35px;
                        border-radius: 0px;
                        color: #fff;
                        cursor: pointer;
                        font-family: Outfit;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 14px;
                        letter-spacing: 0px;
                        text-align: left;

                        img {
                            vertical-align: middle;
                            margin-right: 15px;
                            width: 25px;
                        }
                    }
                }
            }
        }
    }

    .dashboard-active {
        background: #fff !important;
        color: #000000 !important;
    }

    .dashboard-active1 {
        background: #d3d3d3 !important;
        color: #000000 !important;
    }

    .mobile-coming {
        display: none;

        .mobile-inner-hide {
            align-items: center;
            background-color: #f66817;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 9999;

            img {
                margin-bottom: 19px;
                width: 228px;
            }

            h4 {
                color: #fff;
                font-family: Outfit;
                font-size: 20px;
                text-align: center;
            }

            p {
                color: #fff;
                font-family: Outfit;
                font-size: 15px;
                margin-top: 8px;
            }
        }
    }
}

@media (max-width: 767px) {

    // .mobile-coming {
    //     display: block !important;
    // }
    .dashboard-container {
        width: 100%;
        overflow-x: hidden;

        .navbar {
            height: 90px;
            padding: 0px;

            .nav-btns {
                margin-left: 30px;

                button:nth-child(2) {
                    background-color: #fff;
                    color: #000000;
                    font-weight: bold;
                    height: 40px;
                    margin-left: 20px;
                }
            }

            .navbar-nav-scroll {
                max-height: 420px !important;
            }

            .navbar-toggler {
                box-shadow: none;
                border: none;
            }

            .navbar-collapse {
                margin-top: 3px;
                width: 100%;
                // height: 450px;
                z-index: 3;
                padding: 10px 18px;
                background-color: #000000;

                .navbar-nav {
                    .nav-item {
                        .nav-link {
                            padding: 12px 28px;
                        }
                    }
                }
            }
        }

        .side-bar {
            display: none;
        }
    }
}

@media screen and (max-width: 1200px) {
    // .mobile-coming {
    //     display: block !important;
    // }
}

.BodyCloseNav .layout-container {
    padding-left: 15px;
}

// Radio custom

.MuiSnackbar-anchorOriginTopCenter {
    .MuiPaper-root {
        // background-color: $dark-background !important;
        font-weight: 600 !important;
        font-family: "Outfit" !important;
        justify-content: center;
        border-radius: 0px !important;
        font-size: 1.5rem;
        display: flex;
        align-items: center;

        .css-1pxa9xg-MuiAlert-message {
            padding: 35px 10px !important;
        }
    }

    .MuiSnackbarContent-message {
        font-size: 1.5rem;
        color: #fff;
    }
}

@media (max-width: 1024px) {
    .layout-container {
        padding-left: 15px;
    }
}

.footer {
    width: 100%;
    background-color: #1b1a1f;
    padding: 25px 0px;
    z-index: 1;
    padding-bottom: 15px !important;
    position: relative;
    // bottom: -1px;

    h5 {
        font-family: Outfit;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        color: #ffffff;
    }

    ul {
        list-style: none;
        display: flex;
        justify-content: center;
        margin-top: 15px;

        li {
            font-family: Outfit;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            color: #8d8c8f;
            cursor: pointer;
            text-decoration: underline;
            padding: 0px 20px;
        }
    }
}