$button-color: #f37d37;

.login-module {
    // -webkit-background-size: cover !important;
    // -moz-background-size: cover !important;
    // -o-background-size: cover !important;
    // background-size: cover !important;
    // width: 100%;
    // height: 100vh;

    .bg-image {
        width: 100%;
        height: 85vh;
    }

    .signup-img {
        height: 95vh;
    }

    .signup {
        margin-top: 63px;
    }

    section {
        font-family: Outfit;
        font-size: 96px;
        font-weight: 400;
        line-height: 72px;
        letter-spacing: -0.02em;
        text-align: left;
    }

    .login-text {
        position: absolute;
        top: 50%;
        left: 20%;
        transform: translate(-50%, -50%);
        width: 550px;

        section {
            font-family: Outfit;
            font-size: 96px;
            font-weight: 400;
            line-height: 85px;
            text-align: left;
            color: #ffffff;
        }

        p {
            font-family: Outfit;
            text-align: left;
            font-size: 40px;
            font-weight: 400;
            line-height: 55px;
            letter-spacing: -0.01em;
            color: #fff;
        }
    }

    .login-holder {
        background: #ffffff;
        box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
        // height: 480px;
        position: absolute;
        top: 51%;
        left: 80%;
        transform: translate(-50%, -50%);
        padding: 35px 35px;
        // padding-bottom: 12px;
        width: 510px;
        border-radius: 32px;

        input {
            height: 48px;
            border-radius: 8px;
            border: 1px solid #e1e1e3;
            font-family: Outfit;
            color: #717073;
        }

        textarea {
            color: #717073;
        }

        .login-box {
            h6 {
                font-family: Outfit;
                font-size: 32px;
                font-weight: 700;
                line-height: 48px;
                letter-spacing: 0em;
                text-align: left;
                margin: 8px 0px;
                color: #1b1a1f;
            }

            .signup_form {
                background: #fff;
                // padding: 15px;
                border-radius: 10px;

                label {
                    display: inline-block;
                    margin-bottom: 0.5rem;
                }

                .error-message,
                span {
                    color: red;
                }
            }

            .form-group {
                margin-bottom: 1rem;

                .error {
                    position: relative;
                    // top: -4px;
                    display: block;
                    color: red;
                    font-size: 14px;
                    text-align: left;
                }

                button {
                    // border-radius: 0px;
                    font-family: Outfit;
                    font-style: normal;
                    font-weight: normal;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 40px;
                    font-size: 16px;
                }

                label {
                    font-family: Outfit;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 14px;
                    color: #535259;
                }

                input {
                    font-family: Outfit;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 16px;

                    &:focus {
                        box-shadow: none;
                    }
                }
            }

            p {
                font-family: Outfit;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: #1b1a1f;
                margin-top: 5px;
            }

            h3 {
                font-family: Outfit;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #6663ff;
            }

            h4 {
                font-family: Outfit;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                color: #81828c;
            }

            .social-login {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                margin-top: 30px;

                .oauth-btn {
                    width: 100%;
                    border: none;
                    color: #fff;
                    padding: 14px 20px;
                    font-weight: bold;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .forgot-password {
                margin-top: 20px;
                font-size: 16px;
            }
        }

        .otp-holder {
            font-family: Outfit;
            text-align: center;

            // p {
            //     margin-top: 8px;
            //     font-size: 14px;
            //     font-weight: 500;
            // }
            h3 {
                font-size: 25px;
            }

            .otp-fields {
                padding-bottom: 25px;

                p {
                    margin-bottom: 20px;
                    margin-top: 8px;
                    font-weight: 500;
                    font-size: 18px;
                }

                input {
                    font-family: Outfit;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 16px;

                    &:focus {
                        box-shadow: none;
                    }
                }

                aside {
                    font-family: Outfit;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #8d8c8f;
                }
            }
        }
    }

    .primary-button {
        width: 164px;
        height: 48px;
        border-radius: 12px;
    }
}

@media (max-width: 767px) {
    .login-module {
        // overflow: hidden;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #fff;
        min-height: 60.5vh; //used in contactus

        .bg-image {
            height: 83vh;
        }

        .login-holder {
            width: 92%;
            background-color: #fff;
            box-shadow: none;
            padding: 25px 25px;
            margin-top: 20px !important;
            margin-bottom: 20px;
            // margin-left: 15px !important;
            position: unset;
            transform: unset;
            .quotes {
                display: none;
            }

            .login-box {
                .social-login {
                    .oauth-btn {
                        width: 275px;
                        margin-top: 10px;
                    }
                }
            }
        }

        .login-text {
            position: unset;
            display: none;
        }
    }
}

@media screen and (min-width: 430px) and (max-width: 930px) {
    .login-module {
        // height: 69vh;
        .login-holder {
            width: 93% !important;
        }
    }
}
