.content-holder {
    padding-left: 0px;
    padding-right: 0px;

    .banner-image {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            object-fit: cover;
            // position: absolute;
            height: 355px;
            width: 100%;
        }

        h5 {
            position: absolute;
            font-family: Outfit;
            font-size: 96px;
            font-weight: 700;
            line-height: 92px;
            text-align: left;
            color: #ffffff;
        }
    }

    .content {
        padding: 50px 70px;
        width: 100%;

        #accord {
            .accordion-item {
                border: none;

                .accordion-button::after {
                    display: none;
                }
                .exec-name {
                    font-size: 16px;
                    line-height: 24px;
                    text-align: left;
                    font-weight: 600;
                    padding-left: 10px;
                }
                h2 {
                    font-family: Outfit;
                    font-size: 38px;
                    font-weight: 600;
                    line-height: 40.48px;
                    text-align: left;
                    color: #212529;
                    letter-spacing: 1px;
                }

                h3 {
                    font-family: Outfit;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 30px;
                    text-align: left;
                    color: #525252;
                    letter-spacing: 1px;
                }

                p {
                    font-family: Outfit;
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 40px;
                    text-align: left;
                    color: #525252;
                    padding-left: 20px;
                }

                .recipe-img-box {
                    float: right;
                }

                .recipe-img {
                    border-radius: 24px;
                    width: 480px;
                    height: 290px;
                }

                .recipe-right {
                    padding-left: 3rem;
                }

                .accordion-header {
                    padding: 0px 0px;
                    border-radius: 24px;
                    border: 2px solid #ff833b;
                    height: 345px;
                    overflow: hidden;

                    .accordion-button {
                        padding: 24px 20px !important;

                        .w-50 {
                            width: 50% !important;
                        }
                    }
                }

                .active-acc {
                    border-radius: 24px 24px 0px 0px;
                    border-bottom: none !important;
                    background-color: #ffefe5;
                    height: unset !important;
                    overflow: unset !important;
                }

                .active-acc-body {
                    background-color: #ffefe5;
                    border-radius: 0px 0px 24px 24px !important;
                    border-top: none !important;
                }

                .accordion-body {
                    // margin-top: 0px;
                    margin: 0px 0px;
                    // border: 2px solid #ff833b;
                    // border-radius: 24px
                    border: 2px solid #ff833b;

                    .padd {
                        padding-left: 1rem !important;
                    }

                    .view-img {
                        padding-left: 18px;
                        padding-top: 10px;

                        button {
                            background-color: #f66817;
                            color: #fff;
                            border-radius: 4px;
                            border: none;
                            width: 180px;
                            height: 48px;
                            font-family: Outfit;
                        }
                    }

                    .plating-img {
                        width: 180px;
                        height: 180px;
                        border-radius: 24px;
                        cursor: pointer;
                    }
                }
            }

            .accordion-item .accordion-header .accordion-button {
                height: unset;
                padding: 0px 10px;
            }

            // .accordion-item:hover,
            .accordion-button:hover,
            // .accordion-item:hover~.accordion-button,
            .accordion-button:hover~.accordion-item {
                background-color: #ffefe5;
                border-radius: 24px;
            }
        }

        .recipe-item {
            margin-bottom: 30px;
            padding: 20px;
            border: 1px solid #999;
            border-radius: 10px;

            h1 {
                font-size: 34px;
                font-weight: bold;
                text-align: center;
                margin-bottom: 30px;
            }

            h2 {
                font-size: 30px;
                text-align: left;
                margin-bottom: 10px;
                background-color: #f1d689;
                border-radius: 5px;
                padding: 5px 10px 5px 10px;
                font-weight: 500;
            }

            h3 {
                font-size: 26px;
                text-align: left;
                margin-bottom: 10px;
                padding: 5px 10px 5px 10px;
                font-weight: 500;
            }

            p {
                padding-left: 30px;
                font-size: 20px;
                margin-bottom: 10px;
                padding: 0px 10px 5px 10px;
                white-space: pre-wrap;
            }

            ol,
            ul {
                padding-left: 30px;
            }

            li {
                font-size: 20px;
            }
        }
    }

    .content-fav {
        padding: 15px 0px;
        width: 90%;
    }
    .no-recipes-found {
        text-align: center;
        font-size: 30px;
        padding: 50px;
    }

    .more-btn {
        font-family: "Outfit" !important;
        height: 50px;
        font-size: 20px;
        padding: 5px 20px;
        border: none;
        background-color: #f66817;
        border-radius: 4px;
        color: #fff;
        margin-right: 20px;
    }

    .go-back-btn {
        font-family: "Outfit" !important;
        height: 50px;
        font-size: 20px;
        padding: 5px 20px;
        border: none;
        background-color: #999;
        border-radius: 4px;
        color: #fff;
        margin-right: 20px;
    }

    .loader-img {
        width: 500px;
        height: 500px;
    }

    .carousel-item {
        width: fit-content !important;
        left: 38%;
    }

    .slick-slide {
        position: absolute;
    }

    .slick-track {
        display: flex !important;
        align-items: stretch !important;
    }

    .slick-slide {
        height: auto !important;
        margin-right: 25px;
    }

    .slick-disabled {
        // opacity: 0% !important;
        cursor: default !important;
    }

    .slick-list {
        overflow: hidden;
        margin: 0;
        padding: 0;
    }

    .slick-next {
        cursor: pointer;
        position: absolute;
        right: -20px;
        top: 8px;

        &:before {
            font-size: 0px;
            opacity: 0;
            display: none;
        }
    }

    .slick-prev {
        cursor: pointer;
        position: absolute;
        left: -50px;
        top: 31px;

        &:before {
            opacity: 0;
            display: none;
            font-size: 0px;
        }
    }
}
@media (max-width: 767px) {
    .content-holder {
        padding-left: 0px;
        padding-right: 0px;

        .content {
            padding: 10px 20px;

            .loading-center {
                width: 100%;
            }
        }

        #accord {
            .accordion-item {
                h2 {
                    font-size: 26px !important;
                }

                h3 {
                    font-size: 20px !important;
                    margin-bottom: 0px;
                }

                p {
                    font-size: 20px !important;
                }

                .exec-name {
                    font-size: 14px !important;
                }

                .recipe-img-box {
                    float: none !important;
                }

                .recipe-img {
                    width: 290px !important;
                    height: 150px !important;
                }

                .recipe-right {
                    padding-left: 0.7rem !important;
                }

                .accordion-header {
                    height: 310px !important;

                    .accordion-button {
                        padding: 20px 20px !important;
                    }
                }

                .accordion-body {
                    .padd1 {
                        padding-left: 0rem !important;
                    }

                    .plating-img {
                        width: 150px !important;
                        height: 150px !important;
                    }
                }

                .active-acc {
                    padding-bottom: 0px !important;
                }

                .active-acc-body {
                    padding-top: 0px !important;
                }
            }
        }

        .more-btn {
            padding: 5px 15px;
            margin-right: 0px;
        }

        .no-recipes-found {
            padding: 10px;
        }

        .loader-img {
            width: 250px;
            height: 250px;
        }

        .carousel-item {
            width: fit-content !important;
            left: 18%;
        }
    }
}
