code {
  font-family: Outfit;
}

html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
}

body {
  line-height: normal;
  font-family: "Outfit";
  background-color: #ffffff;
  scrollbar-width: thin;

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5fa;
  }
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #ffffff;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    max-height: 10px;
    background-color: #e9e8e8;
  }
}
