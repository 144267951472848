.search-history-holder {
    width: 72vw;
    margin-top: 30px;

    h2 {
        color: #212529;
        font-size: 32px;
        font-weight: 500;
        line-height: 30px;
        font-family: Outfit;

        .clear-btn {
            display: flex;
            align-items: center;
            background-color: #ff0000;
            border: none;
            height: 41px;
            padding: 5px 20px;
            border-radius: 24px;
            font-family: Outfit;
            font-size: 15px;
            font-weight: 500;
            line-height: 20.12px;
            text-align: left;
            color: #fff;
        }
    }

    .content-holder {
        padding-left: 0px;
        padding-right: 0px;

        .content {
            padding: 15px 0px;

            #accord {
                position: relative;

                .delete-row {
                    position: absolute;
                    right: 16px;
                    top: 24px;
                    cursor: pointer;
                }

                .accordion-item {
                    border: none;
                    width: 72vw;

                    .accordion-button::after {
                        display: none;
                    }

                    h2 {
                        font-family: Outfit;
                        font-size: 30px;
                        font-weight: 600;
                        line-height: 35.48px;
                        text-align: left;
                        color: #212529;
                        letter-spacing: 1px;
                        min-height: 85px;
                    }

                    h3 {
                        font-family: Outfit;
                        font-size: 24px;
                        font-weight: 600;
                        line-height: 30px;
                        text-align: left;
                        color: #525252;
                        letter-spacing: 1px;
                    }

                    p {
                        font-family: Outfit;
                        font-size: 24px;
                        font-weight: 400;
                        line-height: 40px;
                        text-align: left;
                        color: #525252;
                        padding-left: 20px;
                    }

                    .accordion-header {
                        padding: 0px 0px;
                        border-radius: 24px;
                        border: 1px solid #ffc29e;
                        height: 100px;
                        overflow: hidden;

                        .accordion-button {
                            padding: 10px 15px !important;
                            background: unset;
                        }

                        .build-btn {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-top: 20px;
                            background-color: #f66817;
                            border: none;
                            border-radius: 4px;
                            color: #fff;
                            width: 166px;
                            height: 45px;
                            font-family: Outfit;
                            font-size: 15.14px;
                            font-weight: 700;
                            line-height: 13.25px;
                            text-align: center;
                        }
                    }

                    .active-acc {
                        border-radius: 24px 24px 0px 0px;
                        border-bottom: none !important;
                        background-color: #ffffff;
                        // height: 220px !important;
                        min-height: 220px !important;
                        overflow: unset !important;
                    }

                    .active-acc-body {
                        background-color: #ffefe5;
                        border-radius: 0px 0px 24px 24px !important;
                        border-top: none !important;
                    }

                    .accordion-body {
                        // margin-top: 0px;
                        margin: 0px 0px;
                        // border: 2px solid #ff833b;
                        // border-radius: 24px
                        background: unset;
                        padding: 8px;
                        border: 1px solid #ffc29e;
                    }
                }

                .accordion-item .accordion-header .accordion-button {
                    height: unset;
                    padding: 0px 10px;
                }

                label {
                    font-family: Outfit;
                    font-size: 17.07px;
                    font-weight: 400;
                    line-height: 34.13px;
                    text-align: left;
                    color: #abaaad;
                }

                h4 {
                    font-family: Outfit;
                    font-size: 20.48px;
                    font-weight: 400;
                    line-height: 34.13px;
                    text-align: left;
                    color: #212121;
                    word-wrap: break-word; /* Break long words if necessary */
                    overflow-wrap: break-word;
                    word-break: break-word;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .search-history-holder {
        width: 92vw;
        margin-top: 20px;
        h2 {
            .clear-btn {
                margin-top: 10px;
                padding: 5px 18px;
            }
        }

        .content-holder #accord {
            h4 {
                margin-bottom: 0px;
            }
            .form-group {
                margin-bottom: 0.5rem;
            }
            .accordion-item .adapt {
                height: 180px !important;
            }
        }
        .content-holder .content #accord .accordion-item {
            width: 92vw;
        }
    }
}
